import areaAPI from "../../helpers/backend/area";

export const state = {
  areaLists: null,
  areaListsTemp: null,
  areaCart: [],
  isOneDaySelected: false,
  createdAreaResponse: null,
  updateAreaSelected: null
};

export const actions = {
  resetStateData({ commit }) {
    commit("resetStateData");
  },
  async getAreaLists({ commit, dispatch }, filters) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await areaAPI.getAvailableArea(filters);
    commit("setAreaLists", result);
    dispatch("app/setOverlayState", false, { root: true });
  },
  async createArea({ dispatch }, newData) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await areaAPI.createArea(newData);
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  async removeArea({ dispatch }, id) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await areaAPI.removeArea(id);
    const filters = { where: { ismaintenance: false } };
    dispatch("getAreaLists", filters);
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  async updateArea({ dispatch }, value) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await areaAPI.updateArea(value);
    dispatch("getAreaLists");
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  setUpdateAreaSelected({ commit }, item) {
    commit("setUpdateAreaSelected", item);
  },
  setAreaEdit({ commit }, item) {
    commit("setAreaEdit", item);
  }
};

export const mutations = {
  resetStateData(state) {
    Object.assign(state, {
      areaLists: null,
      areaCart: [],
      isOneDaySelected: false
    });
  },
  setAreaLists(state, payload) {
    if (payload) {
      if (payload.status === 200) {
        state.areaLists = payload.data;
        state.areaListsTemp = payload.data;
      }
    }
  },
  setAreaEdit(state, item) {
    state.areaLists = item;
  },
  setUpdateAreaSelected(state, item) {
    state.updateAreaSelected = item;
  }
};
