import bookingApi from "../../helpers/backend/booking";
import router from "../../router/index";
import _filter from "lodash/filter";
import _get from "lodash/get";
import _map from "lodash/map";
import _uniq from "lodash/uniq";
import _flattenDeep from "lodash/flattenDeep";
import _groupBy from "lodash/groupBy";
import _reduce from "lodash/reduce";

export const state = {
  bookingItems: null,
  bookingItemSelected: null,
  bookingItemSelectedTemp: null,
  bookFormUpdate: null,
  bookingOwner: null,
  bookingFilterBy: "ทั้งหมด",
  bookingFilterAreaBy: "ทั้งหมด",
  bookingFilterStartDate: null,
  bookingFilterEndDate: null,
  depositCalculateTemp: null,
  summaryPriceCalculateTemp: null,
  maintenanceCostCalculateTemp: null
};

export const getters = {
  checkBookingOnAdminSelector: state => params => {
    let storeDupRoom = [];
    if (state.bookingItems.length > 0) {
      for (const item of state.bookingItems) {
        let filterSameLocaltion = _filter(item.bookingItems, o => {
          return o.room.id === params.room.id;
        });
        if (
          filterSameLocaltion.length > 0 &&
          (item.status === "สำเร็จ" ||
            item.status === "อนุญาต คำขอใช้พื้นที่" ||
            item.status === "ชำระเงินเรียบร้อย" ||
            item.status === "รอการชำระเงิน")
        ) {
          storeDupRoom = storeDupRoom.concat(filterSameLocaltion);
        }
      }

      let isStoreState = [];
      if (storeDupRoom.length > 0) {
        for (const iterator of storeDupRoom) {
          let startdate = new Date(iterator.startDate).setHours(0, 0, 0, 0);
          let enddate = new Date(iterator.endDate).setHours(0, 0, 0, 0);
          let startD = new Date(params.form.startDate).setHours(0, 0, 0, 0);
          let endD = new Date(params.form.endDate).setHours(0, 0, 0, 0);

          let isInRange =
            (startD >= startdate && startD <= enddate) ||
            (startdate >= startD && startdate <= endD) ||
            (startdate == startD && enddate == endD);

          isStoreState.push(isInRange);
        }
        return isStoreState.indexOf(true) !== -1;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  bookingSummaryPriceCalculate: (state, getters) => {
    let summary = 0;
    let objecTivePrice = 0;

    if (state.bookFormUpdate.length > 0) {
      summary += getters.bookingDepositCalculate;

      for (const areaItem of state.bookFormUpdate) {
        const getStartDate = areaItem.startDate;
        const getEndDate = areaItem.endDate;
        const date1 = new Date(getStartDate);
        const date2 = new Date(getEndDate);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

        if (diffDays <= 7) {
          summary += areaItem.maintenanceCost7Days || 0;
        }

        if (diffDays > 7 && diffDays < 30) {
          summary += areaItem.maintenanceCost7Days || 0;
          summary += (diffDays - 7) * areaItem.maintenanceCostOdd || 0;
        }

        if (diffDays === 30) {
          summary += areaItem.maintenanceCost30Days || 0;
        }

        if (diffDays > 30) {
          summary += areaItem.maintenanceCost30Days || 0;
          summary += (diffDays - 30) * areaItem.maintenanceCostOdd || 0;
        }
      }
    }
    return summary + objecTivePrice;
  },
  bookingMaintenanceCostCalculate: state => {
    let summary = 0;
    if (state.bookFormUpdate.length > 0) {
      for (const areaItem of state.bookFormUpdate) {
        const getStartDate = areaItem.startDate;
        const getEndDate = areaItem.endDate;
        const date1 = new Date(getStartDate);
        const date2 = new Date(getEndDate);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

        if (diffDays <= 7) {
          summary += areaItem.maintenanceCost7Days || 0;
        }

        if (diffDays > 7 && diffDays < 30) {
          summary += areaItem.maintenanceCost7Days || 0;
          summary += (diffDays - 7) * areaItem.maintenanceCostOdd || 0;
        }

        if (diffDays === 30) {
          summary += areaItem.maintenanceCost30Days || 0;
        }

        if (diffDays > 30) {
          summary += areaItem.maintenanceCost30Days || 0;
          summary += (diffDays - 30) * areaItem.maintenanceCostOdd || 0;
        }
      }
    }
    return summary;
  },
  bookingDepositCalculate: (state, getters, globalState) => {
    let summary = 0;
    let getApplicantType = _get(
      globalState.booking,
      "bookingItemSelected.applicantType",
      ""
    );
    if (state.bookFormUpdate.length > 0) {
      const getBookingItems = _get(
        state,
        "bookingItemSelected.bookingItems",
        []
      );

      const mergeObjtoBookFromUpdate = [];
      for (const [index, item] of getBookingItems.entries()) {
        const getObj = _get(getBookingItems, `[${index}].objective`, []);
        const getStartDate = _get(
          state,
          `bookFormUpdate[${index}].startDate`,
          ""
        );
        const getEndDate = _get(state, `bookFormUpdate[${index}].endDate`, "");
        const mergeObj = {
          ...item,
          objective: getObj,
          startDate: getStartDate,
          endDate: getEndDate
        };
        mergeObjtoBookFromUpdate.push(mergeObj);
      }

      const groupByObj = _groupBy(mergeObjtoBookFromUpdate, e => {
        const getObj = _get(e, "objective[0]", "");
        return getObj;
      });

      for (const objKey in groupByObj) {
        if (Object.hasOwnProperty.call(groupByObj, objKey) && objKey !== "") {
          const element = groupByObj[objKey];
          const reduceDateCount = _reduce(
            element,
            (sum, n) => {
              const getStartDate = _get(n, "startDate", "");
              const getEndDate = _get(n, "endDate", "");
              const getDepositStartDate = getStartDate;
              const getDepositEndDate = getEndDate;
              const dateDepo1 = new Date(getDepositStartDate);
              const dateDepo2 = new Date(getDepositEndDate);
              const depoDiffTime = Math.abs(dateDepo2 - dateDepo1);
              const depoDiffDays =
                Math.ceil(depoDiffTime / (1000 * 60 * 60 * 24)) + 1;
              return Number(sum) + Number(depoDiffDays);
            },
            0
          );

          let deposit = Math.ceil(reduceDateCount / 7) * 10000;
          if (getApplicantType !== "GOVERNMENTAGENCY") {
            summary += deposit;
          }
        }
      }
    }

    return summary;
  },
  areaGetPricePerRoom: () => selectAreaItem => {
    let summary = 0;
    if (selectAreaItem) {
      let areaItem = selectAreaItem;
      const getStartDate = areaItem.startDate;
      const getEndDate = areaItem.endDate;
      const date1 = new Date(getStartDate);
      const date2 = new Date(getEndDate);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

      if (diffDays <= 7) {
        summary += areaItem.room.maintenanceCost7Days || 0;
      }

      if (diffDays > 7 && diffDays < 30) {
        summary += areaItem.room.maintenanceCost7Days || 0;
        summary += (diffDays - 7) * areaItem.room.maintenanceCostOdd || 0;
      }

      if (diffDays === 30) {
        summary += areaItem.room.maintenanceCost30Days || 0;
      }

      if (diffDays > 30) {
        summary += areaItem.room.maintenanceCost30Days || 0;
        summary += (diffDays - 30) * areaItem.room.maintenanceCostOdd || 0;
      }
    }
    return summary;
  },
  getBookingItems: state => {
    let bookingResult = null;
    if (!state.bookingFilterBy || state.bookingFilterBy === "ทั้งหมด") {
      bookingResult = state.bookingItems;
    } else {
      let filterStatus = _filter(state.bookingItems, o => {
        return o.status === state.bookingFilterBy;
      });
      bookingResult = filterStatus;
    }

    if (state.bookingFilterAreaBy && state.bookingFilterAreaBy !== "ทั้งหมด") {
      let filterArea = _filter(bookingResult, o => {
        let bookingItem = _get(o, "bookingItems", "");
        let getRoomName = _filter(bookingItem, val => {
          return _get(val, "room.name", "") === state.bookingFilterAreaBy;
        });
        return getRoomName.length > 0;
      });
      bookingResult = filterArea;
    }

    if (state.bookingFilterStartDate && state.bookingFilterEndDate) {
      let storeResult = [];
      if (bookingResult.length > 0) {
        for (const iterator of bookingResult) {
          let bookingItem = iterator.bookingItems;
          if (bookingItem) {
            if (bookingItem.length > 0) {
              let isStoreState = [];
              for (const item of bookingItem) {
                let startdate = new Date(item.startDate).setHours(0, 0, 0, 0);
                let enddate = new Date(item.endDate).setHours(0, 0, 0, 0);
                let startD = new Date(state.bookingFilterStartDate).setHours(
                  0,
                  0,
                  0,
                  0
                );
                let endD = new Date(state.bookingFilterEndDate).setHours(
                  0,
                  0,
                  0,
                  0
                );

                let isInRange =
                  startdate >= startD &&
                  startdate <= endD &&
                  enddate >= startD &&
                  enddate <= endD;
                isStoreState.push(isInRange);
              }
              if (isStoreState.indexOf(true) !== -1) {
                storeResult.push(iterator);
              }
            }
          }
        }
        bookingResult = storeResult;
      }
    }

    return bookingResult;
  },
  getStatusGroup: state => {
    if (state.bookingItems) {
      let statusGroup = _map(state.bookingItems, o => o.status);
      statusGroup.push("ทั้งหมด");
      return _uniq(statusGroup);
    }
    return null;
  },
  getAreaFilter: state => {
    if (state.bookingItems) {
      let getRoomList = _map(state.bookingItems, val => {
        return _get(val, "bookingItems", "");
      });
      let flattenDeep = _flattenDeep(getRoomList);
      let getRoomName = _map(flattenDeep, val => {
        return _get(val, "room.name", "");
      });
      getRoomName.push("ทั้งหมด");
      let filterEmpty = _filter(getRoomName, o => o);
      return _uniq(filterEmpty);
    }
    return null;
  }
};

export const actions = {
  async getBookingList({ commit, dispatch }, filters) {
    dispatch("app/setOverlayState", true, { root: true });
    let result = await bookingApi.getBooking(filters);
    commit("setBookingResult", result);
    dispatch("app/setOverlayState", false, { root: true });
  },
  async setUpdateBookingItem({ commit }, param) {
    commit("setBookingItemSelected", param);
    router.push({ path: "/bookingmanagement/manage" });
  },
  async settingBookingStatus({ dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    let response = await bookingApi.setBookingStatus(param);
    dispatch("app/setOverlayState", false, { root: true });
    return response;
  },
  async setUpdateBookingFormUpdate({ commit }, param) {
    commit("setUpdateBookingFormUpdate", param);
  },
  async setRemoveBookingByIndex({ commit }, index) {
    commit("setRemoveBookingByIndex", index);
  },
  async updateBookingItem({ dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    let result = await bookingApi.updateBookingItem(param);
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  async getBookingOwner({ dispatch, commit }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    let result = await bookingApi.getBookingOwner(param);
    commit("setBookingOwner", result);
    dispatch("app/setOverlayState", false, { root: true });
  },
  async setBookingFilterBy({ commit }, type) {
    commit("setBookingFilterBy", type);
  },
  async setBookingFilterAreaBy({ commit }, type) {
    commit("setBookingFilterAreaBy", type);
  },
  async setBookingFilterStartDate({ commit }, type) {
    commit("setBookingFilterStartDate", type);
  },
  async setBookingFilterEndDate({ commit }, type) {
    commit("setBookingFilterEndDate", type);
  },
  async onResetFilter({ commit }, type) {
    commit("onResetFilter", type);
  },
  onDepositCalculateUpdate({ commit }, value) {
    commit("setDepositCalculateUpdate", value);
  },
  onMaintenanceCostCalculateUpdate({ commit }, value) {
    commit("setMaintenanceCostCalculateUpdate", value);
  },
  onSummaryPriceCalculateUpdate({ commit }, value) {
    commit("setSummaryPriceCalculateUpdate", value);
  }
};

export const mutations = {
  onResetFilter(state) {
    state.bookingFilterBy = "ทั้งหมด";
    state.bookingFilterAreaBy = "ทั้งหมด";
    state.bookingFilterStartDate = null;
    state.bookingFilterEndDate = null;
  },
  setBookingResult(state, result) {
    if (result) {
      if (result.status === 200) {
        state.bookingItems = result.data;
      }
    }
  },
  setBookingItemSelected(state, param) {
    state.bookingItemSelected = param;
    state.bookingItemSelectedTemp = { ...param };
    let getBookingItem =
      _get(state.bookingItemSelected, "bookingItems", []) || [];
    let getBookingItemRooms = _map(getBookingItem, o => {
      return {
        ...o.room,
        startDate: o.startDate,
        endDate: o.endDate
      };
    });
    state.bookFormUpdate = getBookingItemRooms;
  },
  setUpdateBookingFormUpdate(state, param) {
    state.bookFormUpdate = param;
  },
  setRemoveBookingByIndex(state, index) {
    state.bookingItemSelected.bookingItems = state.bookingItemSelected.bookingItems.splice(
      index,
      1
    );
    let getBookingItem =
      _get(state.bookingItemSelected, "bookingItems", []) || [];
    let getBookingItemRooms = _map(getBookingItem, o => {
      return {
        ...o.room,
        startDate: o.startDate,
        endDate: o.endDate
      };
    });
    state.bookFormUpdate = getBookingItemRooms;
  },
  setBookingOwner(state, result) {
    if (result) {
      if (result.status === 200) {
        state.bookingOwner = result.data;
      }
    }
  },
  setBookingFilterBy(state, type) {
    if (type) {
      state.bookingFilterBy = type;
    }
  },
  setBookingFilterAreaBy(state, type) {
    if (type) {
      state.bookingFilterAreaBy = type;
    }
  },
  setBookingFilterStartDate(state, type) {
    if (type) {
      state.bookingFilterStartDate = type;
    }
  },
  setBookingFilterEndDate(state, type) {
    if (type) {
      state.bookingFilterEndDate = type;
    }
  },
  setDepositCalculateUpdate(state, type) {
    if (state.depositCalculateTemp === null || type == null) {
      state.depositCalculateTemp = type;
    }
  },
  setMaintenanceCostCalculateUpdate(state, type) {
    if (state.maintenanceCostCalculateTemp === null || type == null) {
      state.maintenanceCostCalculateTemp = type;
    }
  },
  setSummaryPriceCalculateUpdate(state, type) {
    if (state.summaryPriceCalculateTemp === null || type == null) {
      state.summaryPriceCalculateTemp = type;
    }
  }
};
