<template>
  <div id="app">
    <router-view />
    <b-overlay :show="overlayState" no-wrap></b-overlay>
  </div>
</template>

<script>
export default {
  computed: {
    overlayState() {
      return this.$store.state.app.overlay;
    }
  }
};
</script>
