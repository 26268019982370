import axios from "axios";
let JSZip = require("jszip");
// const FileSaver = require("file-saver");

export default {
  async export(param) {
    try {
      const baseUrl = process.env.VUE_APP_URL;

      // const response = await axios.post(`${baseUrl}/export`, param, {
      //   responseType: "blob"
      // });
      // if (response.status === 200) {
      //   const blob = new Blob([response.data]);
      //   FileSaver.saveAs(blob, `แบบขอใช้หอศิลป์_${param.activities}.pdf`);
      // }

      const response = await axios.post(`${baseUrl}/export`, param);
      if (response.status === 200) {
        let zip = new JSZip();
        if (response.data.length > 1) {
          for (const [index, pdf] of response.data.entries()) {
            zip.file(
              `แบบขอใช้หอศิลป์_${param.activities}(${index + 1}).pdf`,
              pdf,
              { base64: true }
            );
          }
          zip.generateAsync({ type: "blob" }).then(blobdata => {
            let zipblob = new Blob([blobdata]);
            var elem = window.document.createElement("a");
            elem.href = window.URL.createObjectURL(zipblob);
            elem.download = "แบบขอใช้หอศิลป์.zip";
            elem.click();
          });
        } else {
          const linkSource = `data:application/pdf;base64,${response.data[0]}`;
          const downloadLink = document.createElement("a");
          const fileName = `แบบขอใช้หอศิลป์_${param.activities}.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }

      // const response = await axios.post(`${baseUrl}/export`, param);
      // if (response.status === 200) {
      //   for (const pdf of response.data) {
      //     const linkSource = `data:application/pdf;base64,${pdf}`;
      //     const downloadLink = document.createElement("a");
      //     const fileName = `แบบขอใช้หอศิลป์_${param.activities}.pdf`;
      //     downloadLink.href = linkSource;
      //     downloadLink.download = fileName;
      //     downloadLink.click();
      //   }
      // }

      return response;
    } catch (error) {
      throw error.response.data;
    }
  },
  logout() {
    localStorage.removeItem("usertoken");
  }
};
