import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue-apexcharts";
import vco from "v-click-outside";
import axios from "axios";

import VueMask from "v-mask";
import store from "@/state/store";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";

Vue.config.productionTip = false;

import { configureFakeBackend } from "./helpers/fakebackend/fake-backend";

if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
  configureFakeBackend();
}

import "@/assets/scss/app.scss";

axios.interceptors.request.use(function(config) {
  const token = JSON.parse(localStorage.getItem("usertoken"));
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.component("apexchart", VueApexCharts);
Vue.use(vco);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
