import bookingAttachFileApi from "../../helpers/backend/bookingattachfile";
import _filter from "lodash/filter";

export const state = {
  bookingAttachItemList: null
};

export const getters = {};

export const actions = {
  async initPage({ dispatch, commit }) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await bookingAttachFileApi.getBookingAttachItemList();
    commit("getBookingAttachItemList", result);
    dispatch("app/setOverlayState", false, { root: true });
  },

  async submit({ dispatch, commit }, items) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await bookingAttachFileApi.createBookingAttachItem(items);
    commit("setBookingAttachItem", result);
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },

  removeItemByStatus({ commit }, status) {
    commit("removeItemByStatus", status);
  }
};

export const mutations = {
  getBookingAttachItemList(state, result) {
    if (result) {
      if (result.status === 200 && result.data.length > 0) {
        state.bookingAttachItemList = result.data;
      }
    }
  },
  setBookingAttachItem(state, result) {
    if (result) {
      if (result.status === 200 && result.data.length > 0) {
        state.bookingAttachItemList = result.data;
      }
    }
  },
  removeItemByStatus(state, status) {
    state.bookingAttachItemList = _filter(
      state.bookingAttachItemList,
      o => o.status !== status
    );
  }
};
