import axios from "axios";

const baseUrl = process.env.VUE_APP_URL;

export default {
  async getBookingAttachItemList() {
    try {
      let url = `${baseUrl}/booking-attach-files`;
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return null;
    }
  },
  async createBookingAttachItem(item) {
    try {
      let url = `${baseUrl}/booking-attach-files`;
      const response = await axios.post(url, item);
      return response;
    } catch (error) {
      return null;
    }
  }
};
