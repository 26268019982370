import exportFileAPI from "../../helpers/backend/exportpdf";

export const state = {};

export const getters = {};

export const actions = {
  async exportFilePDF({ dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    await exportFileAPI.export(param);
    dispatch("app/setOverlayState", false, { root: true });
  }
};

export const mutations = {};
