import regulationsApi from "../../helpers/backend/regulations";

export const state = {
  regulationsData: null,
  createRegulationResponse: null,
  removeRegulationResponse: null,
  updateRegulationSelected: null
};

export const getters = {
  getDocType(state) {
    if (state.regulationsData) {
      return state.regulationsData.filter(o => o.doc_type === "doc");
    } else {
      return null;
    }
  },
  getImageType(state) {
    if (state.regulationsData) {
      return state.regulationsData.filter(o => o.doc_type === "image");
    } else {
      return null;
    }
  }
};

// actions
export const actions = {
  async initPage({ commit, dispatch }, filter) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await regulationsApi.initPage(filter);
    commit("initPage", result);
    dispatch("app/setOverlayState", false, { root: true });
  },
  async createRegulation({ dispatch }, newReq) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await regulationsApi.create(newReq);
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  async removeRegulation({ dispatch }, id) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await regulationsApi.remove(id);
    dispatch("initPage", null);
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  async updateRegulation({ dispatch }, id) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await regulationsApi.update(id);
    dispatch("initPage", null);
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  setUpdateRegulations({ commit }, item) {
    commit("setUpdateAreaSelected", item);
  }
};

// mutations
export const mutations = {
  initPage(state, result) {
    if (result) {
      if (result.status === 200) {
        state.regulationsData = result.data;
      }
    }
  },
  setUpdateAreaSelected(state, param) {
    state.updateRegulationSelected = param;
  }
};
