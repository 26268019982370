import uploadFileApi from "../../helpers/backend/uploadfile";

export const state = {
  filesUploaded: null,
  fileImageActivity: []
};

// actions
export const actions = {
  async uploadFile({ commit }, files) {
    const result = await uploadFileApi.uploadFile(files);
    commit("uploadFile", result);
    return result;
  },
  setFileImageActivity({ commit }, files) {
    commit("setFileImageActivity", files);
  }
};

// mutations
export const mutations = {
  uploadFile(state, result) {
    if (result) {
      if (result.status === 200) {
        state.filesUploaded = result.data;
      }
    }
  },
  setFileImageActivity(state, files) {
    state.fileImageActivity = [...files];
  }
};
