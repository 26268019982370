import api from "../../helpers/backend/applicanttypes";

export const state = {
  applicantTypes: []
};

export const actions = {
  async initPage({ commit, dispatch }) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await api.getApplicantTypes();
    commit("setApplicantTypes", result);
    dispatch("app/setOverlayState", false, { root: true });
  }
};

export const mutations = {
  setApplicantTypes(state, payload) {
    if (payload) {
      if (payload.status === 200) {
        state.applicantTypes = payload.data;
      }
    }
  }
};
