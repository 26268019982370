import axios from "axios";

const baseUrl = process.env.VUE_APP_URL;

export default {
  async getUserList(filters) {
    try {
      let url = "";
      if (filters) {
        const enCodeFilter = encodeURIComponent(JSON.stringify(filters));
        url = `${baseUrl}/userList?filter=${enCodeFilter}`;
      } else {
        url = `${baseUrl}/userList`;
      }
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return null;
    }
  },
  async updateUserProfile(profile) {
    try {
      let url = `${baseUrl}/adminupdate_profile`;

      const response = await axios.patch(url, profile);
      return response;
    } catch (error) {
      return null;
    }
  }
};
