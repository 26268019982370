import axios from "axios";

const baseUrl = process.env.VUE_APP_URL;

export default {
  async getOrganizationTypes(filter) {
    try {
      let url = "";
      if (filter) {
        const enCodeFilter = encodeURIComponent(JSON.stringify(filter));
        url = `${baseUrl}/organizations-types?filter=${enCodeFilter}`;
      } else {
        url = `${baseUrl}/organizations-types`;
      }
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return null;
    }
  },
  async getOrganizationsRequireDocs(filters) {
    try {
      let url = "";
      if (filters) {
        const enCodeFilter = encodeURIComponent(JSON.stringify(filters));
        url = `${baseUrl}/organizations-require-docs?filter=${enCodeFilter}`;
      } else {
        url = `${baseUrl}/organizations-require-docs`;
      }
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return null;
    }
  },
  async getOrganizationTypeById(param) {
    try {
      let url = "";
      if (param.filters) {
        const enCodeFilter = encodeURIComponent(JSON.stringify(param.filters));
        url = `${baseUrl}/organizations-types/${param.id}?filter=${enCodeFilter}`;
      } else {
        url = `${baseUrl}/organizations-types`;
      }
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return null;
    }
  },
  async createOrganizationsRequireDocs(newItem) {
    try {
      const response = await axios.post(
        `${baseUrl}/organizations-require-docs`,
        newItem
      );
      return response;
    } catch (error) {
      return null;
    }
  },
  async removeOrganizationsRequireDocs(id) {
    try {
      const response = await axios.delete(
        `${baseUrl}/organizations-require-docs/${id}`
      );
      return response;
    } catch (error) {
      return null;
    }
  }
};
