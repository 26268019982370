import api from "../../helpers/backend/organizationtype";
import _filter from "lodash/filter";

export const state = {
  organizationTypes: [],
  organizationRequireDocTypes: null,
  organizationsRequireDocs: []
};

export const getters = {
  getOrgRequireRemoveId: state => param => {
    if (state.organizationsRequireDocs.length > 0) {
      let result = _filter(state.organizationsRequireDocs, o => {
        return (
          o.organizationsTypeId === param.organizationsTypeId &&
          o.requireDocId === param.requireDocId
        );
      });
      return result;
    }
    return null;
  }
};

export const actions = {
  async getOrganizationTypes({ commit, dispatch }, filter) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await api.getOrganizationTypes(filter);
    commit("setOrganizationTypes", result);
    dispatch("app/setOverlayState", false, { root: true });
  },
  async getOrganizationsRequireDocs({ commit }, filters) {
    const result = await api.getOrganizationsRequireDocs(filters);
    commit("setOrganizationsRequireDocs", result);
  },
  async getOrganizationTypeById({ commit, dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await api.getOrganizationTypeById(param);
    commit("setgetOrganizationTypeById", result);
    dispatch("app/setOverlayState", false, { root: true });
  },
  async createOrganizationsRequireDocs({ dispatch }, param) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await api.createOrganizationsRequireDocs(param);
    let getParam = {
      filters: { include: [{ relation: "requireDocs" }] },
      id: param.organizationsTypeId
    };
    dispatch("getOrganizationTypeById", getParam);
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  async removeOrganizationsRequireDocs({ dispatch }, { orgId, removeId }) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await api.removeOrganizationsRequireDocs(removeId);
    let getParam = {
      filters: { include: [{ relation: "requireDocs" }] },
      id: orgId
    };
    dispatch("getOrganizationTypeById", getParam);
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  }
};

export const mutations = {
  setOrganizationTypes(state, payload) {
    if (payload) {
      if (payload.status === 200) {
        state.organizationTypes = payload.data;
      }
    }
  },
  setgetOrganizationTypeById(state, payload) {
    if (payload) {
      if (payload.status === 200) {
        state.organizationRequireDocTypes = payload.data;
      }
    }
  },
  setOrganizationsRequireDocs(state, payload) {
    if (payload) {
      if (payload.status === 200) {
        state.organizationsRequireDocs = payload.data;
      }
    }
  }
};
