import userApi from "../../helpers/backend/user";

export const state = {
  userList: null,
  updateUserItem: null
};

export const actions = {
  async getUserList({ commit, dispatch }, filter) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await userApi.getUserList(filter);
    commit("getUserList", result);
    dispatch("app/setOverlayState", false, { root: true });
  },
  setUpdateUserItem({ commit }, item) {
    commit("setUpdateUserItem", item);
  },
  async updateProfileUser({ dispatch }, value) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await userApi.updateUserProfile(value);
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  }
};

export const mutations = {
  getUserList(state, result) {
    if (result) {
      if (result.status === 200) {
        state.userList = result.data;
      }
    }
  },
  setUpdateUserItem(state, item) {
    state.updateUserItem = item;
  }
};
