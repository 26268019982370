import api from "../../helpers/backend/internalgroup";
import _get from "lodash/get";
import _differenceBy from "lodash/differenceBy";

export const state = {
  internalGroupList: [],
  updateItem: null
};

export const getters = {
  getInternalGroupByOrg: state => param => {
    if (state.internalGroupList.length > 0 && param !== null) {
      let avaInternalGroupList = _get(param, "organizationtype.internalGroups", "");
      if (avaInternalGroupList) {
        let result = _differenceBy(
          state.internalGroupList,
          avaInternalGroupList,
          "id"
        );
        return result;
      } else {
        return state.internalGroupList;
      }
    }
    return state.internalGroupList;
  }
};

export const actions = {
  resetStateData({ commit }) {
    commit("resetStateData");
  },
  async getInternalGroupLists({ commit }, filters) {
    const result = await api.initPage(filters);
    commit("setInternalGroupLists", result);
  },
  async create({ dispatch }, newData) {
    dispatch("app/setOverlayState", true, { root: true });
    newData.sequence = parseInt(newData.sequence);
    const result = await api.create(newData);
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  async remove({ dispatch }, id) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await api.remove(id);
    dispatch("getInternalGroupLists", {});
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  async update({ dispatch }, value) {
    dispatch("app/setOverlayState", true, { root: true });
    value.sequence = parseInt(value.sequence);
    const result = await api.update(value);
    dispatch("getInternalGroupLists", {});
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  },
  setUpdateItem({ commit }, item) {
    commit("setUpdateItem", item);
  }
};

export const mutations = {
  setInternalGroupLists(state, payload) {
    if (payload) {
      if (payload.status === 200) {
        state.internalGroupList = payload.data;
      }
    }
  },
  resetStateData(state) {
    Object.assign(state, {
      areaLists: null,
      areaCart: [],
      isOneDaySelected: false
    });
  },
  setUpdateItem(state, item) {
    state.updateItem = item;
  }
};
