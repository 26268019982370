import sendMailApi from "../../helpers/backend/sendmail";

export const state = {};

export const actions = {
  async sendMail({ dispatch }, files) {
    dispatch("app/setOverlayState", true, { root: true });
    const result = await sendMailApi.sendMail(files);
    dispatch("app/setOverlayState", false, { root: true });
    return result;
  }
};

export const mutations = {};
