import axios from "axios";

const baseUrl = process.env.VUE_APP_URL;

export default {
  async sendMail(params) {
    try {
      let url = `${baseUrl}/sendmail`;
      const response = await axios.post(url, params);
      return response;
    } catch (error) {
      return null;
    }
  }
};
