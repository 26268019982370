import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home"
          });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some(route =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
                name: "home"
              }
            : {
                ...routeFrom
              }
        );
      }
    }
  },
  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/rulemanagement/index")
  },
  {
    path: "/rulemanagement",
    name: "rulemanagement",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/rulemanagement/index")
  },
  {
    path: "/rulemanagement/create",
    name: "addrule",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/rulemanagement/add-rule")
  },
  {
    path: "/rulemanagement/update",
    name: "updaterule",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/rulemanagement/update-rule")
  },
  {
    path: "/roommanagement",
    name: "roommanagement",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/roommanagement/index")
  },
  {
    path: "/roommanagement/create",
    name: "addroom",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/roommanagement/add-room")
  },
  {
    path: "/roommanagement/update",
    name: "updateroom",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/roommanagement/update-room")
  },
  {
    path: "/requiredoc",
    name: "requiredoc",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/requiredoc/index")
  },
  {
    path: "/requiredoc/create",
    name: "createrequiredoc",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/requiredoc/creatae-requiredoc")
  },
  {
    path: "/requiredoc/update",
    name: "updaterequiredoc",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/requiredoc/update-requiredoc")
  },
  {
    path: "/applicanttyperequiredoc",
    name: "applicanttype",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/applicanttyperequiredoc/index")
  },
  {
    path: "/bookingmanagement",
    name: "bookingmanagement",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/bookingmanagement/index")
  },
  {
    path: "/bookingmanagement/manage",
    name: "manage booking",
    meta: {
      authRequired: true
    },
    component: () =>
      import("../views/pages/bookingmanagement/manage-booking.vue")
  },
  {
    path: "/usermanagement",
    name: "user manage",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/usermanagement/index.vue")
  },
  {
    path: "/usermanagement/update",
    name: "update user",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/usermanagement/update-user.vue")
  }
  // {
  //   path: "/bookingattachfile",
  //   name: "booking attach file",
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import("../views/pages/bookingattachfile/index.vue")
  // }
  ,{
    path: "/internaldepartment",
    name: "internaldepartment",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/internaldepartment/index")
  },
  {
    path: "/internaldepartment/create",
    name: "createinternaldepartment",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/internaldepartment/creatae-internaldepartment")
  },
  {
    path: "/internaldepartment/update",
    name: "updateinternaldepartment",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/internaldepartment/update-internaldepartment")
  }
  ,{
    path: "/internalgroup",
    name: "internalgroup",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/internalgroup/index")
  },
  {
    path: "/internalgroup/create",
    name: "createinternalgroup",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/internalgroup/creatae-internalgroup")
  },
  {
    path: "/internalgroup/update",
    name: "updateinternalgroup",
    meta: {
      authRequired: true
    },
    component: () => import("../views/pages/internalgroup/update-internalgroup")
  }
];
