import axios from "axios";

const baseUrl = process.env.VUE_APP_URL;

export default {
  async getBooking(filters) {
    try {
      let url = "";
      if (filters) {
        const enCodeFilter = encodeURIComponent(JSON.stringify(filters));
        url = `${baseUrl}/bookings?filter=${enCodeFilter}`;
      } else {
        url = `${baseUrl}/bookings`;
      }
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return null;
    }
  },
  async setBookingStatus(updateValue) {
    try {
      const response = await axios.patch(
        `${baseUrl}/bookings/${updateValue.id}`,
        updateValue
      );
      return response;
    } catch (error) {
      return null;
    }
  },
  async updateBookingItem(updateItem) {
    try {
      let storeResponse = [];
      for (const item of updateItem) {
        let response = await axios.patch(
          `${baseUrl}/booking-items/${item.id}`,
          item
        );
        storeResponse.push(response);
      }
      return storeResponse;
    } catch (error) {
      return null;
    }
  },
  async getBookingOwner(param) {
    try {
      let response = await axios.post(`${baseUrl}/bookingOwner`, param);
      return response;
    } catch (error) {
      return null;
    }
  }
};
