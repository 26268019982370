export const state = () => ({ overlay: false });

export const getters = {
  parseJwt() {
    let token = JSON.parse(localStorage.getItem("usertoken"));
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
};

export const actions = {
  setOverlayState({ commit }, overlayState) {
    commit("setOverlayState", overlayState);
  }
};

export const mutations = {
  setOverlayState(state, overlayState) {
    state.overlay = overlayState;
  }
};
