import authenticationApi from "../../helpers/backend/authentication";
import router from "../../router/index";

export const state = {
  currentUser: sessionStorage.getItem("authUser")
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  }
};

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch("validate");
  },

  login({ dispatch, commit }, { email, password }) {
    authenticationApi.login({ email, password }).then(
      user => {
        commit("loginSuccess", user);
        router.push("/");
        commit("loginRequest", { email });
      },
      error => {
        commit("loginFailure", error);
        dispatch(
          "notification/error",
          "Login failed: Your user ID or password is incorrect",
          { root: true }
        );
      }
    );
  },

  logout({ commit }) {
    authenticationApi.logout();
    commit("logout");
  }

  // Logs in the current user.
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
    saveState("auth.currentUser", newValue);
  },
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  loginSuccess() {}
};

// ===
// Private helpers
// ===

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}
