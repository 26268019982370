import axios from "axios";

const baseUrl = process.env.VUE_APP_URL;

export default {
  async initPage(filters) {
    try {
      let url = "";
      if (filters) {
        const enCodeFilter = encodeURIComponent(JSON.stringify(filters));
        url = `${baseUrl}/internal-department?filter=${enCodeFilter}`;
      } else {
        url = `${baseUrl}/internal-department`;
      }
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return null;
    }
  },
  async create(newItem) {
    try {
      const response = await axios.post(`${baseUrl}/internal-department`, newItem);
      return response;
    } catch (error) {
      return null;
    }
  },
  async remove(id) {
    try {
      const response = await axios.delete(`${baseUrl}/internal-department/${id}`);
      return response;
    } catch (error) {
      return null;
    }
  },
  async update(value) {
    try {
      const response = await axios.patch(
        `${baseUrl}/internal-department/${value.id}`,
        value
      );
      return response;
    } catch (error) {
      return null;
    }
  }
};
