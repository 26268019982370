import axios from "axios";
import _get from "lodash/get";

export default {
  async login(form) {
    try {
      const baseUrl = process.env.VUE_APP_URL;
      if (form.email === process.env.VUE_APP_ADMIN_EAMIL) {
        const response = await axios.post(`${baseUrl}/users/login`, form);

        let getToken = _get(response, "data.token", "");
        localStorage.setItem("usertoken", JSON.stringify(getToken));

        return response;
      } else {
        let errorObj = {
          error: {
            message: "Invalid email or password.",
            name: "UnauthorizedError",
            statusCode: 401
          }
        };
        throw errorObj;
      }
    } catch (error) {
      throw error.response.data;
    }
  },
  logout() {
    localStorage.removeItem("usertoken");
  }
};
