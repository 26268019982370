import axios from "axios";

const baseUrl = process.env.VUE_APP_URL;

export default {
  async getApplicantTypes() {
    try {
      let url = `${baseUrl}/applicant-types`;
      const response = await axios.get(url);
      return response;
    } catch (error) {
      return null;
    }
  },
  async createArea(newArea) {
    try {
      const response = await axios.post(`${baseUrl}/rooms`, newArea);
      return response;
    } catch (error) {
      return null;
    }
  },
  async removeArea(id) {
    try {
      const response = await axios.delete(`${baseUrl}/rooms/${id}`);
      return response;
    } catch (error) {
      return null;
    }
  },
  async updateArea(value) {
    try {
      const response = await axios.patch(`${baseUrl}/rooms/${value.id}`, value);
      return response;
    } catch (error) {
      return null;
    }
  }
};
